<template>
  <div class="page">
    <div id="top" class="search_wap">
      <div class="a-inline-block">
        <a-input-search v-model="key" class="sInput" placeholder="名称、编号、客户" @search="onSearch" />
      </div>
    </div>
    
        
        <div v-if="this.pagination.total" style="text-align: right;margin-right: 20px;">当前数据条数{{this.pagination.total}}条</div>
    <div v-infinite-scroll="getData" infinite-scroll-disabled="dataLoadStop" infinite-scroll-distance="50">
      <div class="lists" v-for="(item,index) in data" :key="index">
        <div class="list_row">
          <div class="flex">
            <div><label for="">项目名称：</label>{{item.pname}}</div>
            <!-- <div><label for="">合同编号：</label>{{item.orderid}}</div> -->
          </div>
          <div class="flex">
            <div><label for="">时间：</label>{{item.createtime}}</div>
            <div><label for="">状态：</label>{{item.pstatus==2?'已完结':'进行中'}}</div>
          </div>
          <div class="flex">
            <div><label for="">客户名称：</label>{{item.cname}}</div>
            <div><label for="">客户电话：</label>{{item.ctel}}</div>
          </div>

          <div class="flex">
            <div><label for="">项目分类：</label>{{item.project_type}}</div>
            <div><label for="">地址：</label>{{item.address}}</div>
          </div>
          <div class="flex">
            <div><label for="">负责人：</label>{{item.sname}}</div>
            <div><label for="">介绍人：</label>{{item.introduce}}</div>
          </div>
        </div>
        <div class="btn_con">
          <!-- <a-button type="primary" size="small" ghost @click="showDetail(item.id)">查看</a-button> -->
          <a-button type="primary" size="small" ghost @click="edit(item.id)">编辑</a-button>
          <!-- <a-popconfirm title="确定要关闭吗?"  @confirm="() => off(item.id)">
                    <a-button type="danger" size="small" ghost>关闭</a-button>
                </a-popconfirm> -->
          <a-button type="primary" size="small" ghost @click="showLabor(item.id)">添加工时</a-button>
          <a-button type="primary" size="small" ghost @click="toDetail(item.id)">工时记录</a-button>
          <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
            <a-button type="danger" size="small" ghost>删除</a-button>
          </a-popconfirm>
        </div>
      </div>
      <!-- <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
        <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
      </div> -->
      
        <a-pagination v-if="pagination.total" style="text-align: center;" @change="onChanges" v-model="pagination.current" :total="pagination.total" />
        <div v-if="pagination.total==0" class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
            <span>--暂无数据--</span>
        </div>
    </div>
    <a-modal v-model="showModal" :centered="true" title="添加工时" @ok="setLabor()">
      <div class="addBox" style="padding-bottom:50px">
        <div class="add_title">请选择工人</div>
        <a-select v-model="onTemp" mode="multiple" class="ant-select-lg" style="width: 100%;">
          <a-select-option :value="item.id" v-for="(item,index) in tempList" :key="index">{{item.name}}
          </a-select-option>
        </a-select>
        <div class="add_title">请输入出勤时长（天）</div>
        <a-input class="add_inp ant-input-lg" type="number" style="width: 100%;" v-model="laborTime" step="0.1" />
        <div class="add_title">请输入工作日期</div>
        <a-date-picker @change="onPickTime" :inputReadOnly="true" style="width: 100%;" size="large" />
        <div class="add_title">请选择工作内容</div>
        <a-select v-model="onSkill" class="ant-select-lg" style="width: 100%;">
          <a-select-option :value="item.id" v-for="(item,index) in skillList" :key="index">{{item.name}}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>
    <!-- <a-pagination
                v-if="data.length>0"
                class="page_select"
                v-model="pagination.current"
                :total="pagination.total"
                @change="pagesChange"
            /> -->
  </div>
</template>
<script>
// import $ from "jquery";
import { requestXml, ajaxUrl } from "../../../assets/js/request";
import infiniteScroll from "vue-infinite-scroll";

export default {
  directives: { infiniteScroll },
  data() {
    return {
      // 搜索的内容
      key: "",
      data: [],
      ajaxUrl: ajaxUrl,
      showModal: false, //添加工时弹框
      onItem: "",
      tempList: [], //工人列表
      onTemp: [],
      skillList: [], //技能列表
      onSkill: undefined,
      laborTime: "", //出勤工时
      laborDate: "", //出勤日期
      // 分页
      dataLoadStop: false, //列表数据加载停止状态
      dataLoadNomore: false, //列表数据加载是否完成
      pagination: {
        current: 1,
        total: 100,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showQuickJumper: true,
        // showTotal: total => `Total ${total} items`, // 显示总数
        onChange: (current) => this.pagesChange(current), //点击当前页码
        onShowSizeChange: (current, pageSize) => {
          //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
          console.log(pageSize);
          this.showSizeChange(current, pageSize);
        },
      },
    };
  },
  
    beforeRouteEnter(to, from, next) {
      next(vm=>{
        vm.fromPath = from.path
      })
    },
    beforeDestroy(){
        sessionStorage.setItem('curpage',JSON.stringify({'key': this.key,
          'time': this.selMonth,
          'responsibility': this.selStaff,
          'status': this.selStatus,'page':this.pagination}))
    },
    mounted: function(){
        if(this.fromPath == "/scrm_wap/item/itemAdd" || this.fromPath == "/scrm_wap/item/itemDetail") {
            var data=JSON.parse(sessionStorage.getItem('curpage'))
            sessionStorage.removeItem('curpage')
            this.pagination=data.page
            this.key=data.key
            this.selMonth=data.time
            this.selStaff=data.responsibility
            this.selStatus=data.status
        }
        this.getData();
        this.getSkill();
    },
    methods:{
    onChanges(){
            window.location.href='#top'
      console.log(111)
      this.getData();
    },
    pagesChange(current) {
      //current参数表示是点击当前的页码，
      this.pagination.current = current;
      this.getData(); //向后端发送请求
    },
    showSizeChange(current, pageSize) {
      console.log(current, pageSize);
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getData(); //向后端发送请求
    },
    // 搜索
    // 搜索
    onSearch() {
      this.data = [];
      this.dataLoadNomore = false;
      this.pagination.current = 1;
      this.getData();
    },
    toDetail(id) {
      this.$router.push({
        path: "/scrm_wap/item/itemDetail",
        query: {
          id: id,
        },
      });
    },
    // 获取技能列表
    getSkill() {
      requestXml(
        "/jieb/Temporary/skillshow",
        "POST",
        (res) => {
          this.skillList = res.list;
        },
        { page: { curpage: 1, pagesize: 9999 } }
      );
    },
    // 获取工人列表
    getTemp(id) {
      requestXml(
        "/jieb/Project/attendanceadd",
        "GET",
        (res) => {
          this.tempList = res;
        },
        { id: id }
      );
    },

    // 显示添加工时弹框
    showLabor(id) {
      this.showModal = true;
      this.onItem = id;
      this.getTemp(id);
    },
    onPickTime(date, dateString) {
      console.log(date);
      this.laborDate = dateString;
    },
    setLabor() {
      let temp = this.onTemp;
      let skill = this.onSkill;
      if (temp.length < 1 || skill == "") {
        this.$message.info("请选择工人或者工作内容");
        return false;
      }
      console.log(this.laborTime,this.laborDate);
      if (this.laborTime == "" || this.laborDate == "") {
        this.$message.info("请填写工时或者工作日期");
        return false;
      }

      requestXml(
        "/jieb/Project/attendanceadd",
        "POST",
        (res) => {
          if (res != "errer") {
            this.showLabor = false;
            this.onItem = "";
            this.onSkill = undefined;
            this.laborTime = ""; //出勤工时
            this.laborDate = ""; //出勤日期
            this.showModal = false;
            this.$message.success("添加成功");
          }
        },
        {
          projectid: this.onItem,
          temporaryid: temp.join(","),
          skillid: skill,
          time: this.laborTime,
          date: this.laborDate,
        }
      );
    },
    getData() {
      // this.dataLoadStop = true;
      var onTime = "";
      if (this.selMonth > 0) {
        onTime = this.selMonth - 28800;
      }
      requestXml(
        "/jieb/Project/projectshow",
        "POST",
        (res) => {
          console.log(res);

          for (let i = 0; i < res.list.length; i++) {
            res.list[i].createtime =
              res.list[i].createtime.split(" ")[0] +
              "至" +
              res.list[i].endtime.split(" ")[0];
          }
          this.data = res.list
          // this.data = this.data.concat(res.list);
          // if (res.page.maxpage > this.pagination.current) {
          //   this.pagination.current += 1;
          //   this.dataLoadStop = false;
          // } else {
          //   this.dataLoadNomore = true;
          // }
          this.search = res.search;
          this.pagination.total = Number(res.page.total);
          this.pagination.pageSize = Number(res.page.pagesize);
        },
        {
          key: this.key,
          time: onTime,
          responsibility: this.selStaff,
          status: this.selStatus,
          page: {
            curpage: this.pagination.current,
            pagesize: this.pagination.pageSize,
          },
        }
      );
    },
    // 编辑
    edit(id) {
      this.$router.push({
        path: "/scrm_wap/item/itemAdd",
        query: {
          id: id,
        },
      });
    },
    // 删除
    onDelete(id) {
      requestXml(
        "/jieb/Project/projectdel",
        "POST",
        (res) => {
          console.log(res);
          this.pagination.current=1;
          this.data=[];
          this.getData();
        },
        { id: id }
      );
    },
  },
};
</script>
<style scoped>
.page_select {
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  background: #fff;
}
.search_wap {
  text-align: left;
  margin-bottom: 15px;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
}
.search_wap .ant-input-search {
  width: 90vw;
  margin: 0 5vw;
}

.lists {
  width: 92%;
  /* height: 26rem; */
  padding: 3rem;
  margin: 15px auto;
  background-color: #fff;
  border-radius: 10px;
  color: #202020;
}
/* .lists .list_row div{
        height: 5rem;
        line-height: 5rem;
        text-align: left;
    } */
.lists .list_row .flex {
  justify-content: space-between;
  padding: 5px 0;
}
.lists .item {
  display: flex;
}
.lists .right {
  text-align: left;
  padding-left: 20px;
  width: calc(100% - 100px);
}
.lists .right .flex {
  display: flex;
  justify-content: space-between;
}
.btn_con {
  text-align: left;
}
.btn_con button {
  color: #36a3ff;
  border-color: #36a3ff;
  margin: 1rem 1rem 0 0;
}
.add_title {
  padding-top: 10px;
  padding-bottom: 5px;
}
</style>
